import ZineList from "../components/ZineList";

//TODO
//add material ui styling here?
//not sure if i should from this level but may be helpful

function HomePage() {
    return (
        <>
            <ZineList />
        </>
    )
}

export default HomePage;